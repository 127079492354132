const connexion = [
  /* {
    path: "/",
    name: "connexion",
    component: () => import("@/views/Signin"),
  }, */
];

const admin = [
  {
    path: "/acceuil",
    meta: {roles: ['ROLE_ADMIN','ROLE_GUARDIAN']},
    name: "dashboard",
    component: () => import("@/views/Dashboard"),
  },
  {
    path: "/statistiques",
    meta: {roles: ['ROLE_ADMIN','ROLE_USER','ROLE_GUARDIAN']},
    name: "homeWelcome",
    component: () => import("@/views/Home"),
  },
  {
    path: "/liste/noire",
    meta: {roles: ['ROLE_ADMIN']},
    name: "listeNoire",
    component: () => import("@/views/admin/ListeNoire"),
  },
  {
    path: "/parametre",
    meta: {roles: ['ROLE_ADMIN']},
    name: "parametre",
    component: () => import("@/views/admin/Parametres"),
  },
  /* {
    path: "/entree",
    meta: {roles: ['ROLE_ADMIN']},
    name: "entree",
    props: (route) => ({
        ...route.params
    }),
    component: () => import("@/views/admin/Entree"),
  }, */
  {
    path: "/entree/sortie",
    meta: {roles: ['ROLE_ADMIN']},
    name: "entreeSortie",
    props: (route) => ({
        ...route.params
    }),
    component: () => import("@/views/admin/EntreeSortie"),
  },
  {
    path: "/sortie",
    meta: {roles: ['ROLE_ADMIN', 'ROLE_GUARDIAN']},
    name: "sortie",
    props: true,
    component: () => import("@/views/admin/Sortie"),
  },
  {
    path: "/referentiel/badge",
    meta: {roles: ['ROLE_ADMIN']},
    name: "badgeReferentiel",
    component: () => import("@/views/referentiel/Badge.vue")
  },
  {
    path: "/referentiel/direction",
    meta: {roles: ['ROLE_ADMIN']},
    name: "directionReferentiel",
    component: () => import("@/views/referentiel/Direction.vue")
  },
  {
    path: "/referentiel/succursales",
    meta: {roles: ['ROLE_ADMIN']},
    name: "succursalesReferentiel",
    component: () => import("@/views/referentiel/Succursale.vue")
  },
  {
    path: "/referentiel/organigramme",
    meta: {roles: ['ROLE_ADMIN']},
    name: "organigrammeReferentiel",
    component: () => import("@/views/referentiel/Service.vue")
  },
  {
    path: "/referentiel/visites",
    meta: {roles: ['ROLE_ADMIN']},
    name: "visitesReferentiel",
    component: () => import("@/views/admin/Visite.vue")
  },
  {
    path: "/referentiel/annonce/visite",
    meta: {roles: ['ROLE_ADMIN', 'ROLE_USER']},
    name: "annoncesReferentiel",
    component: () => import("@/views/admin/Annonce.vue")
  },
  {
    path: "/referentiel/nature/visite",
    meta: {roles: ['ROLE_ADMIN']},
    name: "natureReferentiel",
    component: () => import("@/views/referentiel/NatureVisite.vue")
  },
  {
    path: "/referentiel/privilege",
    meta: {roles: ['ROLE_ADMIN']},
    name: "privilegeReferentiel",
    component: () => import("@/views/referentiel/Privilege.vue")
  },
  {
    path: "/referentiel/role",
    meta: {roles: ['ROLE_ADMIN']},
    name: "roleReferentiel",
    component: () => import("@/views/referentiel/Role.vue")
  },
  {
    path: "/referentiel/type/document",
    meta: {roles: ['ROLE_ADMIN']},
    name: "typeDocumentReferentiel",
    component: () => import("@/views/referentiel/TypeDocument.vue")
  },
  {
    path: "/referentiel/personnel",
    meta: {roles: ['ROLE_ADMIN']},
    name: "personnelReferentiel",
    component: () => import("@/views/referentiel/Personnel.vue")
  },
  {
    path: "/referentiel/users",
    meta: {roles: ['ROLE_ADMIN']},
    name: "usersReferentiel",
    component: () => import("@/views/referentiel/Users.vue")
  },
  /* {
    path: "/roles",
    meta: {roles: ['ROLE_ADMIN']},
    name: "roleCreer",
    component: () => import("@/views/role/role.vue")
  }, */
];

export default [
  {
    path: "/login",
    component: () => import("@/views/layout/Admin"),
    children: connexion,
  },
  {
    path: "/admin",
    component: () => import("@/views/layout/Admin"),
    meta: {roles: ['ROLE_ADMIN']},
    children: admin,
  }
];
