import https from "@/services/";
import Vue from 'vue';


export default {
    middleware: async ({to, store, next}) => {
        if (to.meta.roles && to.meta.roles.length > 0)
            if (Vue.$cookies.get('eVisiteTokJwt')?.jwtToken) {
                let profile = await store.dispatch("connexion/profile");
                if(to.meta.roles.includes(profile.roles[0].name)){
                    next({replace: true});
                }else{next({name: 'homeWelcome'});}
            } else {
                next({name: 'signin', query: {redirect: to.fullPath}});
            }
        else next();
    },
    interceptor(error, store, router) {
        const { response, config } = error, token = Vue.$cookies.get('eVisiteTokJwt')?.jwtToken;
        if (!response) return Promise.reject(error);
        const { status, data } = response
        console.log(status)

        if ( token && status === 401) {
            return store.dispatch("connexion/logout").then(() =>
                router.replace({
                    name: 'signin', query: {
                        redirect: (router.currentRoute || {}).fullPath
                    }
                }).then(() => { })
            )
        }
        //return Promise.reject(response);
    }
};