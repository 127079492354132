<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
/* import './assets/lib/jquery/jquery.min.js';
import './assets/lib/jquery.dcjqaccordion.2.7.js';
import './assets/lib/jquery.scrollTo.min.js';
import './assets/lib/jquery.nicescroll.js';
import './assets/lib/jquery.sparkline.js';
import './assets/lib/common-scripts.js';
import './assets/lib/gritter/js/jquery.gritter.js';
import './assets/lib/gritter-conf.js'; */


import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  mounted() {
    let scripts = [
      //"/assets/js/jquery.min.js",
      //"/assets/js/specific.js",
      "/assets/js/bootstrap.bundle.min.js",
      "/assets/js/select2.min.js",
    ];

    for (let i = 0; i < scripts.length; i++) {
      const element = scripts[i];
      const plugin = document.createElement("script");
      plugin.setAttribute("src", element);
      plugin.async = true;
      document.head.appendChild(plugin);
    }

  }
}
</script>

<style>
@import '@/assets/css/bootstrap.css';
@import '@/assets/css/bootstrap-customer.css';
@import '@/assets/css/bootstrap-icons.css';
@import '@/assets/css/select2-bootstrap-5-theme.css';
@import '@/assets/css/style.css';
@import '@/assets/css/select2.min.css';
@import '~sweetalert2/dist/sweetalert2.min.css';
@import '~primevue/resources/themes/bootstrap4-light-blue/theme.css';
@import '~primevue/resources/primevue.min.css';
@import '~primeicons/primeicons.css';
@import '~vue-multiselect/dist/vue-multiselect.min.css';
</style>
