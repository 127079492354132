import Vue from 'vue'
import App from './App.vue'
import Vuex from "vuex";
import Vuer from "vue-router";
import helps from "./helpers/";
import https from './services/';
import Paginate from 'vue-pagination-2';
import PrimeVue from 'primevue/config';
import Dropdown from 'primevue/dropdown';
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Fieldset from 'primevue/fieldset';
import stores from "./stores";
import routes from "./routes";
import Spinner from 'vue-spinkit';
import { extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import _fr from 'vee-validate/dist/locale/fr.json';
import VueLoading from 'vuejs-loading-plugin';
import Vuet from "vue-sweetalert2";
import VueCookies from 'vue-cookies';
import VueToast from 'vue-toast-notification';
//import $ from 'jquery'
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import MultiSelect from 'vue-multiselect'
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import OrganizationChart from 'vue-organization-chart'
import 'vue-organization-chart/dist/orgchart.css'

import "bootstrap";
//require('bootstrap');

/* import './assets/lib/jquery/jquery.min.js';
import './assets/lib/bootstrap/js/bootstrap.min.js';
import './assets/lib/jquery.dcjqaccordion.2.7.js';
import './assets/lib/jquery.scrollTo.min.js';
import './assets/lib/jquery.nicescroll.js';
import './assets/lib/jquery.sparkline.js';
import './assets/lib/common-scripts.js';
import './assets/lib/gritter/js/jquery.gritter.js';
import './assets/lib/gritter-conf.js';
import './assets/lib/sparkline-chart.js';
import './assets/lib/zabuto_calendar.js'; */

import moment from 'moment';
import Vuem from "vue-moment";

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

Vue.config.productionTip = false
Vue.component('OrganizationChart', OrganizationChart);
Vue.component('Dropdown', Dropdown);
Vue.component('Spinner', Spinner);
Vue.component('Paginate', Paginate);
Vue.component('Sidebar', Sidebar);
Vue.component('Dialog', Dialog);
Vue.component('Button', Button);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('MultiSelect', MultiSelect);
Vue.component('Fieldset', Fieldset);
Vue.use(VueCookies);
Vue.use(PrimeVue);
Vue.use(Vuer).use(Vuet).use(Vuex).use(VueToast);
Vue.use(VueLoading, {
  dark: true,
  text: 'Un instant . . .',
}).use(Vuem, { moment });

Vue.mixin({
  methods: {
    dateToYMD(end_date) {
        var ed = new Date(end_date);
        var d = ed.getDate();
        var m = ed.getMonth() + 1;
        var y = ed.getFullYear();
        return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }
  }
});

const store = new Vuex.Store(stores), router = new Vuer(routes);

router.beforeEach(
  async (to, from, next) =>
      await helps.middleware({ to, store, next })
);
https.interceptors.response.use(undefined, err => helps.interceptor(err, store, router));

for (const rule of Object.keys(rules)) extend(rule, rules[rule]);

extend('integer', {
  message: 'Ce champ doit être un nombre uniquement'
});

extend('containsUppercase', value => {
  if (/[A-Z]/.test(value)) {
    return true;
  }

  return 'Le champ {_field_} doit contenir au moins une lettre majuscule';
});

extend('codePays', value => {
  if (value.includes("+")) {
    return true;
  }

  return 'Le Pays doit être sélectionné';
});

extend('containsLowercase', value => {
  if (/[a-z]/.test(value)) {
    return true;
  }

  return 'Le champ {_field_} doit contenir au moins une lettre miniscule';
});

extend('containsNumber', value => {
  if (/[0-9]/.test(value)) {
    return true;
  }

  return 'Le champ {_field_} doit contenir au moins un nombre';
});

extend('email', {
  message: 'L\'e-mail doit être une adresse de format conforme'
});

localize(_fr.code, _fr);
moment.locale('fr');

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')