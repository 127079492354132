import client from '@/services/';

const state = () => ({
    all: [],
    allValide: [],
    one: {}
});

const getters = {
    all: state => state.all,
    allValide: state => state.allValide,
    one: state => state.one
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ALL_VALIDE: (state, obj) => {
        state.allValide = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
};

const actions = {
    async getAll(cxt, payload) {
        let { data } = await client.get('visite/all');
        cxt.commit("SET_ALL", data);
        return data;
    },
    async getValide(cxt, payload = {}) {
        let { data } = await client.get('visite/today', {params: payload.payload}, payload.page);
        cxt.commit("SET_ALL_VALIDE", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        });
        return data;
    },
    async getStat(cxt) {
        let { data } = await client.get('visite/stats');
        return data;
    },
    async isPresent(cxt, payload = {}) {
        let { data } = await client.get('visite/document/check/'+payload.numero);
        return data;
    },
    async getAllP(cxt, payload = {}) {
        let { data } = await client.get('visite', {params: payload.payload}, payload.page);
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getOne(cxt, id) {
        let { data } = await client.get('visite/one/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async out(cxt, id) {
        let { data } = await client.get('visite/sortie/'+id);
        //cxt.commit("SET_ONE", data);
        return data;
    },
    async find(cxt, code) {
        let { data } = await client.get('visite/badge/'+code);
        //cxt.commit("SET_ONE", data);
        return data;
    },
    async create(cxt, payload) {
        let { data } = payload.id ? await client.put('visite/edit/'+payload.id, payload) : await client.post('visite', payload);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async del(cxt, id) {
        let { data } = await client.delete('visite/delete/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
