import connexion from "./modules/connexion";
import role from "./modules/role";
import user from "./modules/user";
import personnel from "./modules/personnel";
import annonce from "./modules/annonce";
import badge from "./modules/badge";
import nature from "./modules/nature";
import typeDocument from "./modules/typeDocument";
import privilege from "./modules/privilege.js";
import succursales from "./modules/succursales.js";
import direction from "./modules/direction.js";
import service from "./modules/service";
import visite from "./modules/visite";
import listeNoire from "./modules/listeNoire";

const store = {
  modules: {
    connexion: connexion,
    listeNoire: listeNoire,
    user: user,
    annonce: annonce,
    personnel: personnel,
    role: role,
    privilege: privilege,
    badge: badge,
    typeDocument: typeDocument,
    nature: nature,
    succursales: succursales,
    direction: direction,
    service: service,
    visite: visite
  },
};

export default store;
