import client from '@/services/';
import Vue from 'vue';
import { COOKIES_EXPIRE } from '@/config/config';

const state = () => ({
    current: {},
    profile: {},
    logged: !!Vue.$cookies.get('eVisiteTokJwt')
});

const getters = {
    user: state => Vue.$cookies.get('eVisiteTokJwt'),
    profile: state => state.profile,
    connected: state => state.logged,
    userType: state => {
        let roles = state.logged ? state.profile.roles[0] : "";
        switch (roles) {
            case "ROLE_ADMIN":
                return "ADMIN";
                break;
            case "ROLE_DIRECTION":
                return "DIRECTION";
                break;
            case "ROLE_PERSONNEL":
                return "PERSONNEL";
                break;
            default:
                break;
          }
    },
};

const mutations = {
    SET_CONNECTED: (state, obj) => {
        state.current = obj;
    },
    SET_PROFILE: (state, obj) => {
        state.profile = obj;
    },
    SET_STATUS: (state, obj) => {
        state.logged = obj != null;
    },
};

const actions = {
    signin: async (cxt, obj = {}) => {
        return client.post('/user/signin', obj).then(({data}) => {
            cxt.commit("SET_CONNECTED", data);
            return data;
        });
    },
    signup(cxt, payload) {
        return client.post('/api/auth/signup', payload).then(({data}) => {return data;});
    },
    reset(cxt, payload) {
        return client.post('/api/auth/resetpassword', payload).then(({data}) => {return data;});
    },
    profile(cxt, payload) {
        return client.get('user/profile').then((data) => {
            if(data){
                let transformed = {
                    ...data.data,
                    isAdmin: data.data.roles[0].name === "ROLE_ADMIN" ? true : false,
                    isGuardian: data.data.roles[0].name === "ROLE_GUARDIAN" ? true : false,
                    isUser: data.data.roles[0].name === "ROLE_USER" ? true : false
                };
                cxt.commit("SET_PROFILE", transformed);
                return transformed;
            }
        });
    },
    editProfile(cxt, payload) {
        return client.put('user/update/profil/'+payload.id, payload).then(({data}) => {
            cxt.commit("SET_PROFILE", data);
            return data;
        });
    },
    logout: ({commit}) => new Promise(res => {
        Vue.$cookies.remove("eVisiteTokJwt");
        commit("SET_STATUS", null);
        res();
    })
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
