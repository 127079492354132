import client from '@/services/';

const state = () => ({
    all: [],
    one: {}
});

const getters = {
    all: state => state.all,
    one: state => state.one
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
};

const actions = {
    async getAll(cxt, payload) {
        let { data } = await client.get('user/all');
        cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllP(cxt, payload = 0) {
        let { data } = await client.get('user', {params: {page: payload}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async create(cxt, payload) {
        let { data } = await client.post('user/add', payload);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async createAdmin(cxt, payload) {
        let { data } = await client.post('user/admin/signup', payload);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async createGuardian(cxt, payload) {
        let { data } = await client.post('user/guardian/signup', payload);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async createUser(cxt, payload) {
        let { data } = await client.post('user/client/signup', payload);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async update(cxt, payload) {
        let { data } = await client.put('user/'+payload.id, payload);
        cxt.commit("SET_ONE", data);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
